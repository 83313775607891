import ConnectionLayout from '~/components/ConnectionLayout';
import LoadingBar from '~/components/LoadingBar';

import { MESSAGES } from '~/utils/messages';
import { text } from '~/utils/constants';

const Idle = () => (
  <ConnectionLayout>
    <h1 class={text.heading2XL}>{MESSAGES.READY_TO_CAST_TITLE}</h1>
    <h2 class={text.bodyL}>{MESSAGES.SELECT_CONTENT_ON_DEVICE}</h2>
    <div class="flex flex-col gap-6 mt-auto">
      <p class={text.heading2XS}>{MESSAGES.SELECT_CONTENT_TO_STREAM}</p>
      <LoadingBar />
    </div>
  </ConnectionLayout>
);

export default Idle;
