import ConnectionLayout from '~/components/ConnectionLayout';
import LoadingBar from '~/components/LoadingBar';
import { MESSAGES } from '~/utils/messages';
import { text } from '~/utils/constants';

const LaunchingScreen = () => (
  <ConnectionLayout>
    <div class="flex flex-col gap-6 mt-auto">
      <p class={text.headingXS}>{MESSAGES.CONNECTING_YOUR_DEVICE}</p>
      <LoadingBar animated />
    </div>
  </ConnectionLayout>
);

export default LaunchingScreen;
