import { createEffect, createSignal, onCleanup } from 'solid-js';

import { VrioOttError } from '@dtvgo/error';
import {
  isPlayerErrorSeverityCritical,
  isPlayerErrorSeverityNonCritical,
  isErrorRecoverable,
} from '~/utils/functions';

import { addErrorListener, PlayerState, PlayerStateType } from '~/cast/lib';

import { errorFactory } from '~/utils/errorFactory';
import { usePlayerRecovery } from './usePlayerRecovery';

export function createErrorSignal(
  playerState: () => PlayerStateType,
  recoveryConfig: () =>
    | {
        baseDelay: number;
        maxReloadTime: number;
        maxAttempts: number;
        resetTime: number;
        backoffFactor?: number;
        fuzzFactor?: number;
      }
    | undefined,
) {
  const [error, setError] = createSignal<VrioOttError | undefined>(undefined);
  const { attemptRecovery, isRecovering } = usePlayerRecovery(recoveryConfig);
  let cleanErrorTimeout: ReturnType<typeof setTimeout> | undefined;

  const handleError = (castError: unknown) => {
    if (!castError) return;

    const vrioOttError = errorFactory.ensureVrioOttError(castError);

    // If the error is not recoverable, error is not a player exception
    if (!isErrorRecoverable(vrioOttError)) {
      // if the error is not critical, there is no need to recover
      if (isPlayerErrorSeverityNonCritical(vrioOttError)) {
        return;
      }
      // If the error is critical, set the error
      setError(vrioOttError);
      return;
    }
    // If the error is a player exception, but is not critical, we don't need to recover
    if (!isPlayerErrorSeverityCritical(vrioOttError)) {
      return;
    }

    // Attempt to recover the error
    attemptRecovery(vrioOttError)
      .then((recovered) => {
        if (recovered) {
          setError(undefined);
        } else {
          setError(vrioOttError);
        }
        return recovered;
      })
      .catch(() => {
        console.warn('[ErrorSignal] Recovery attempt failed');
        setError(undefined);
        return false;
      });
  };

  // Add error listener
  createEffect(() => {
    const cleanup = addErrorListener(handleError);
    onCleanup(cleanup);
  });

  // Clear error when we start to load a new content
  createEffect(() => {
    if (playerState() === PlayerState.LOADING) setError(undefined);
  });

  onCleanup(() => {
    if (cleanErrorTimeout) {
      clearTimeout(cleanErrorTimeout);
      cleanErrorTimeout = undefined;
    }
  });

  return [error, handleError, isRecovering] as const;
}
