import { VrioOttError } from '@dtvgo/error';
import { createEffect, createSignal, onCleanup } from 'solid-js';

import { PlayerState, PlayerStateType } from '~/cast/lib';

export enum CastUIState {
  LAUNCHING = 'LAUNCHING',
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  CONTROLS = 'CONTROLS',
  BUFFERING = 'BUFFERING',
  ERROR = 'ERROR',
  NONE = 'NONE',
}

export function useUiState(
  playerState: () => PlayerStateType,
  error: () => VrioOttError | undefined,
  isRecovering: () => boolean,
) {
  const [isLoading, setIsLoading] = createSignal(false);
  const [displayControls, setDisplayControls] = createSignal(false);

  let displayControlsTimeout: ReturnType<typeof setTimeout> | undefined;

  // Update loading state
  createEffect(() => {
    const currentPlayerState = playerState();
    if (currentPlayerState === PlayerState.LOADING) {
      // Set to true when we enter in loading state
      setIsLoading(true);
    } else if (currentPlayerState !== PlayerState.BUFFERING) {
      // Set back to false when we enter in playing state
      setIsLoading(false);
    }
  });

  // Update controls display
  createEffect((prevState?: PlayerStateType) => {
    const currentState = playerState();
    if (currentState === PlayerState.PAUSED) {
      // When we enter in paused state
      // Clear the timeout to hide controls if any
      if (displayControlsTimeout) {
        clearTimeout(displayControlsTimeout);
        displayControlsTimeout = undefined;
      }
      // And, Set display controls to true to keep displaying after we leave paused state
      setDisplayControls(true);
    } else if (
      prevState === PlayerState.PAUSED &&
      currentState === PlayerState.PLAYING
    ) {
      // If we were in paused state and we enter in playing state
      // Set a timeout to hide them
      displayControlsTimeout = setTimeout(() => {
        setDisplayControls(false);
        displayControlsTimeout = undefined;
      }, 5000);
    } else {
      // If we are not in pause and didn't switch from paused to playing
      // Hide controls immediately
      setDisplayControls(false);
      // And, clear the timeout to hide controls if any
      if (displayControlsTimeout) {
        clearTimeout(displayControlsTimeout);
        displayControlsTimeout = undefined;
      }
    }

    return currentState;
  });

  onCleanup(() => {
    if (displayControlsTimeout) {
      clearTimeout(displayControlsTimeout);
      displayControlsTimeout = undefined;
    }
  });

  // eslint-disable-next-line sonarjs/prefer-immediate-return -- avoid error with solid reactivity
  const uiState = () => {
    const currentPlayerState = playerState();

    if (isRecovering()) return CastUIState.BUFFERING;

    if (currentPlayerState === PlayerState.LAUNCHING)
      return CastUIState.LAUNCHING;

    if (currentPlayerState === PlayerState.IDLE) {
      if (error()) return CastUIState.ERROR;

      return CastUIState.IDLE;
    }

    if (currentPlayerState === PlayerState.LOADING || isLoading())
      return CastUIState.LOADING;

    if (currentPlayerState === PlayerState.BUFFERING)
      return CastUIState.BUFFERING;

    if (currentPlayerState === PlayerState.PAUSED || displayControls())
      return CastUIState.CONTROLS;

    return CastUIState.NONE;
  };

  return uiState;
}
