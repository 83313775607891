import { WONDERWALL_FEATURE_FLAG } from '../utils/env';
import jsonSKY from '../../wonderwall-project-SKY.json' assert { type: 'json' };
import jsonDGO from '../../wonderwall-project-DGO.json' assert { type: 'json' };
import { JSONData } from './interface';

export function getJsonImage(imageName: string): string | undefined {
  if (WONDERWALL_FEATURE_FLAG) {
    const theme = import.meta.env.VITE_THEME;
    const json: JSONData =
      theme === 'skymais' ? (jsonSKY as JSONData) : (jsonDGO as JSONData);

    return (json.images as unknown as Record<string, string>)[imageName];
  }
  return undefined;
}
