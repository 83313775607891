import { createEffect, createSignal } from 'solid-js';
import { addPlayingListener, reload } from '~/cast/lib/utils';
import { VrioOttError } from '@dtvgo/error';
import { resolveAfter } from '~/utils/functions';
import Backoff from './backoff';

export function usePlayerRecovery(
  recoveryConfig: () =>
    | {
        baseDelay: number;
        maxReloadTime: number;
        maxAttempts: number;
        resetTime: number;
        backoffFactor?: number;
        fuzzFactor?: number;
      }
    | undefined,
) {
  const [isRecovering, setIsRecovering] = createSignal(false);
  const backoff = new Backoff({ ...recoveryConfig });

  createEffect(() => {
    const newConfig = recoveryConfig();
    backoff.updateOptions({ ...newConfig });
  });

  let resetTimeout: number | undefined;

  const attemptRecovery = async (error: VrioOttError): Promise<boolean> => {
    // If already recovering, don't attempt recovery
    if (isRecovering()) return true;

    const nextDelay = backoff.getNextDelay();

    if (nextDelay === -1) {
      setIsRecovering(false);
      return false;
    }

    setIsRecovering(true);

    // Reset the reset timeout
    clearTimeout(resetTimeout);

    try {
      await resolveAfter(nextDelay);
      // Try to reload the current content and wait until it's in PLAYING state
      await restartPlayback(recoveryConfig()?.maxReloadTime ?? 30_000);

      // If we get here, the recovery was successful
      // Schedule the backoff reset after a time
      resetTimeout = setTimeout(() => {
        backoff.reset();
      }, recoveryConfig()?.resetTime ?? 10_000) as unknown as number;
      setIsRecovering(false);
      return true;
    } catch (error_) {
      console.warn('[ErrorSignal] Recovery attempt failed, error:', error_);
      setIsRecovering(false);

      return attemptRecovery(error);
    }
  };

  return {
    attemptRecovery,
    isRecovering,
  } as const;
}

function restartPlayback(maxTime: number): Promise<void> {
  return new Promise((resolve, reject) => {
    let isFulfilled = false;
    let timeout: ReturnType<typeof setTimeout>;

    // Configure the listener for the PLAYING state
    const removeListener = addPlayingListener(() => {
      if (!isFulfilled) {
        isFulfilled = true;
        clearTimeout(timeout);
        removeListener();
        resolve();
      }
    });

    // Configure the timeout for the case of failure
    timeout = setTimeout(() => {
      if (!isFulfilled) {
        isFulfilled = true;
        removeListener();
        reject();
      }
    }, maxTime);

    // Try to reload the content after configuring the listeners
    reload();
  });
}
