/**
 * Class representing a backoff mechanism for retrying an operation with increasing delays.
 * Inspired by shaka.net.Backoff class
 */
interface BackoffOptions {
  maxAttempts?: number;
  baseDelay?: number;
  backoffFactor?: number;
  fuzzFactor?: number;
}

export default class Backoff {
  /**
   * Default options for Backoff class
   * resetTime is the number of milliseconds to wait before auto resetting the backoff
   * The rest has the same semantics as Shaka retryParameters
   * https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.RetryParameters
   */
  static readonly defaultOptions = {
    maxAttempts: 2,
    baseDelay: 1000,
    backoffFactor: 2,
    fuzzFactor: 0.5,
  };

  #options: Required<BackoffOptions>;

  #attempts: number;

  #nextDelay: number;

  constructor(options: Partial<BackoffOptions>) {
    this.#options = { ...Backoff.defaultOptions, ...options };
    this.#attempts = 0;
    this.#nextDelay = this.#options.baseDelay;
  }

  /**
   * Get the next delay according to the exponential backoff.
   * Returns -1 if the maximum number of attempts has been reached.
   */
  getNextDelay(): number {
    if (this.#attempts >= this.#options.maxAttempts) {
      return -1;
    }

    const delay = Backoff.fuzz(this.#nextDelay, this.#options.fuzzFactor);

    this.#attempts += 1;
    this.#nextDelay *= this.#options.backoffFactor;

    return delay;
  }

  /**
   * Reset the backoff to its initial state
   */
  reset(): void {
    this.#attempts = 0;
    this.#nextDelay = this.#options.baseDelay;
  }

  updateOptions(options: BackoffOptions) {
    this.#options = { ...Backoff.defaultOptions, ...options };
    if (this.#attempts === 0) this.#nextDelay = this.#options.baseDelay;
  }

  /**
   * Applies a random factor to the delay
   */
  private static fuzz(value: number, fuzzFactor: number): number {
    const negToPosOne = Math.random() * 2 - 1;
    const negToPosFuzzFactor = negToPosOne * fuzzFactor;
    return value * (1 + negToPosFuzzFactor);
  }
}
