import { createMemo, Show } from 'solid-js';
import { usePlayerData } from '~/contexts/PlayerDataContext';

import AssetMetadata from '~/components/AssetMetadata';
import LoadingBar from '~/components/LoadingBar';

import ChannelLogoContainer from '~/containers/ChannelLogo';

import { MESSAGES } from '~/utils/messages';
import { getImage, resizeImage } from '~/utils/metadata';
import { text } from '~/utils/constants';

const LoadingScreen = () => {
  const playerData = usePlayerData();

  const isLive = () => playerData.media?.customData?.isLive ?? false;
  const asset = () => playerData.media?.customData?.asset;

  const posterUrl = createMemo(() => {
    const assetImages = asset()?.images;
    if (!assetImages) return undefined;

    const posterImage = getImage(assetImages, '16:9');
    return posterImage && resizeImage(posterImage, 1920).url;
  });

  return (
    <div class="flex flex-col w-screen h-screen relative p-40 justify-end bg-neutral-bg-primary/85">
      <Show when={posterUrl()}>
        <img
          class="absolute top-0 left-0 w-full h-full object-fill -z-10"
          src={posterUrl()}
          alt={`${playerData.media!.customData!.asset!.title} Poster`}
        />
      </Show>
      <div class="flex flex-col gap-12">
        <Show when={isLive()}>
          <ChannelLogoContainer
            channelId={playerData.media!.customData!.assetId}
          />
        </Show>
        <Show when={asset()}>
          <AssetMetadata isLive={isLive()} asset={asset()!} />
        </Show>
        <div class="flex flex-col mt-9 gap-6">
          <p class={text.heading2XS}>{MESSAGES.LOADING_CONTENT}</p>
          <LoadingBar animated />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
