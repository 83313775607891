import { Region } from '@dtvgo/toolbox-service';
import { WONDERWALL_FEATURE_FLAG } from './env';

export const IS_BRAZIL = import.meta.env.VITE_THEME === 'skymais';
export const LANGUAGE = import.meta.env.VITE_THEME === 'skymais' ? 'pt' : 'es';

export const TOOLBOX_REGION =
  import.meta.env.VITE_THEME === 'skymais' ? Region.BR : Region.SSLA;

export const NAMESPACE = 'urn:x-cast:com.directvgo.cast.chromecast';

export enum CustomMessageType {
  ASSET_LOAD = 'assetLoad',
  ERROR = 'error_cast',
  CONNECTED = 'type_connection',
}

export const text = {
  // IBM PLEX SANS
  caption: WONDERWALL_FEATURE_FLAG ? 'ww-text-caption' : '',
  bodyS: WONDERWALL_FEATURE_FLAG ? 'ww-text-bodyS' : 'text-body-s',
  bodyM: WONDERWALL_FEATURE_FLAG ? 'ww-text-bodyM' : 'text-body-m',
  bodyL: WONDERWALL_FEATURE_FLAG ? 'ww-text-bodyL' : 'text-body-l',
  // POPPINS
  overline: WONDERWALL_FEATURE_FLAG ? 'ww-text-overline' : 'text-overline',
  heading2XS:
    WONDERWALL_FEATURE_FLAG ? 'ww-text-heading2XS' : 'text-heading-2xs',
  headingXS: WONDERWALL_FEATURE_FLAG ? 'ww-text-headingXS' : 'text-heading-xs',
  headingS: WONDERWALL_FEATURE_FLAG ? 'ww-text-headingS' : 'text-heading-s',
  headingM: WONDERWALL_FEATURE_FLAG ? 'ww-text-headingM' : '',
  headingL: WONDERWALL_FEATURE_FLAG ? 'ww-text-headingL' : '',
  heading2XL:
    WONDERWALL_FEATURE_FLAG ? 'ww-text-heading2XL' : 'text-heading-2xl',
  heading3XL:
    WONDERWALL_FEATURE_FLAG ? 'ww-text-heading3XL' : 'text-heading-3xl',
  heading4XL: WONDERWALL_FEATURE_FLAG ? 'ww-text-heading4XL' : '',
  heading5XL: WONDERWALL_FEATURE_FLAG ? 'ww-text-heading5XL' : '',
  heading6XL: WONDERWALL_FEATURE_FLAG ? 'ww-text-heading6XL' : '',
};
