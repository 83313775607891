import { defaultsDeep } from 'lodash-es';

import { BufferConfig } from '@dtvgo/player-common-utils';

import { IS_BRAZIL } from './constants';

export type TokenType = 'queryParam' | 'header' | 'cookie';

export interface AppConfig {
  lowLatency: boolean;
  useStreamingV4: boolean;
  parentalControl: boolean;
  shakaVersion: string;
  heartbeatTimeSeconds: number;
  buffer: {
    live: BufferConfig;
    vod: BufferConfig;
  };
  cdnTokenizationTokenType: TokenType[];
  metadata: {
    live: {
      secondsToRecheckWhenNotFound: number;
      maxRetriesWhenNotFound: number;
    };
    vod: Record<string, never>;
  };
  recovery: {
    baseDelay: number;
    maxReloadTime: number;
    maxAttempts: number;
    resetTime: number;
    backoffFactor?: number;
    fuzzFactor?: number;
  };
}

const DEFAULT_APP_CONFIG: AppConfig = {
  lowLatency: false,
  useStreamingV4: false,
  parentalControl: !!IS_BRAZIL,
  shakaVersion: '4.10.6',
  heartbeatTimeSeconds: 90,
  buffer: {
    live: {
      forward: 12,
      backward: 30,
    },
    vod: {
      forward: 30,
      backward: 60,
    },
  },
  cdnTokenizationTokenType: ['queryParam'],
  metadata: {
    live: {
      secondsToRecheckWhenNotFound: 5,
      maxRetriesWhenNotFound: 3,
    },
    vod: {},
  },
  recovery: {
    baseDelay: 1000,
    maxReloadTime: 20_000,
    maxAttempts: 3,
    resetTime: 10_000,
    backoffFactor: 2,
    fuzzFactor: 0.5,
  },
};

/**
 * Returns a Promise that resolves to the app config. The config
 * is fetched from a remote source and defaults to a hardcoded
 * config if the fetch fails.
 */
export function getAppConfig() {
  return fetchAppConfig()
    .then((data) => defaultsDeep({}, data, DEFAULT_APP_CONFIG) as AppConfig)
    .catch((error) => {
      console.error('[getAppConfig] Failed to fetch app config', error);
      return DEFAULT_APP_CONFIG;
    });
}

function fetchAppConfig() {
  // TODO: Replace Promise.resolve({}) to real remote config API call
  return Promise.resolve({});
}
