import { idToken } from '~/store/credentials';
import { profile } from '~/store/profile';
import { Asset, Schedule } from '~/utils/metadata';

declare module 'chromecast-caf-receiver/cast.framework.messages' {
  interface MediaInformationCustomData {
    assetId: string;
    forgerockTokensData?: {
      idToken: string;
    };
    isLive: boolean;
    playSource: string;
    senderName: string;
    senderVersion: string;
    selectedProfile?: { id: string };
    asset?: Asset | Schedule;
    channelId?: string;
    provider?: string;
    vodPlayStartTime?: number;

    schedules?: Schedule[];
    reload?: boolean;
  }
}

export function getLoadRequest(
  assetId: string,
  isLive = false,
  {
    playSource = '',
    senderName = '',
    senderVersion = '',
  }: {
    playSource?: string;
    senderName?: string;
    senderVersion?: string;
  } = {},
) {
  const loadRequest = new cast.framework.messages.LoadRequestData();

  loadRequest.media = new cast.framework.messages.MediaInformation();
  loadRequest.media.customData = {
    isLive,
    assetId,
    selectedProfile: { id: profile()!.id },
    forgerockTokensData: { idToken: idToken()! },
    playSource,
    senderName,
    senderVersion,
  };
  loadRequest.autoplay = true;

  return loadRequest;
}
